@use "sass:math";

//--ATOMS 
//--Colors-----
$primary-glass-green-70:#00312F;
$primary-glass-green-30:#00736D;
$primary-glass-green:#00A59C;
$primary-glass-green-400:#80D2CE;
$primary-glass-green-300:#CCEDEB;
$primary-glass-green-200:#E6F6F5;
$primary-glass-green-100:#F2FBFA;

$primary-dark-blue:#162E5C;

$secondary-steel:#1B242B;
$secondary-white: #ffffff;

$secondary-metal-70:#282D2D;
$secondary-metal-30:#5F6A6A;
$secondary-metal:#879797;
$secondary-metal-400:#C3CBCB;
$secondary-metal-300:#DBE0E0;
$secondary-metal-200:#E7EAEA;
$secondary-metal-100:#F3F5F5;

$secondary-blue-70:#001D31;
$secondary-blue-30:#004473;
$secondary-blue:#0061A4;
$secondary-blue-400:#80B0D2;
$secondary-blue-300:#CCDFED;
$secondary-blue-200:#E6EFF6;
$secondary-blue-100:#F2F7FB;

$tertiary-red-70:#360000;
$tertiary-red-30:#7F0000;
$tertiary-red:#B50000;
$tertiary-red-400:#DA8080;
$tertiary-red-300:#E9B3B3;
$tertiary-red-200:#F0CCCC;
$tertiary-red-100:#F8E6E6;

$tertiary-green:#008000;

$tertiary-dark-green:#005A00;

$tiny-yellow-50:#805500;
$tiny-yellow-40:#996600;
$tiny-yellow-30:#B37700;
$tiny-yellow-warning:#FFAA00;
$tiny-yellow-300:#FFD580;
$tiny-yellow-200:#FFEECC;
$tiny-yellow-100:#FFF7E6;

$pink-red:#A50063;
$black: #000000;
$light-border: #C3CBCB;
$light-border1: #BFBFBF;

$hero-gradient:linear-gradient(90deg, $primary-dark-blue 0%, $secondary-blue 50.56%, $primary-glass-green 100%);
$element-gradient:linear-gradient(180deg, #003152 0%, $secondary-blue 100%);
$steel-gradient:linear-gradient(180deg, $secondary-metal-200 0%, $secondary-metal-100 100%);
$navigation-gradient:linear-gradient(180deg, #0B102F 0%, $primary-dark-blue 100%);

//////////////////////////
$dark-grey-color:$secondary-metal-30;
$black-pearl-color:$secondary-steel;
$very-light-blue-color:#54BCE3;


// $brand-primary-gradient:transparent linear-gradient(90deg, #102548 0%, #083A6F 31%, #044481 45%, #005096 60%, #108396 86%, #1AA296 100%) 0% 0% no-repeat padding-box;
// $brand-secondary-gradient:transparent linear-gradient(182deg, $secondary-blue 0%, #003152 100%) 0% 0% no-repeat padding-box;

$logo-color:$primary-dark-blue;

//--Label Color---//////////////
$btn-primary-bg-color:$primary-glass-green;
$btn-primary-hover-bg-color:$primary-glass-green-30;

$btn-secondary-txt-color:$primary-glass-green;
$btn-secondary-bor-color:$primary-glass-green;
$btn-secondary-hover-txt-color:$primary-glass-green;
$btn-secondary-hover-bg-color: $primary-glass-green-200;
$btn-secondary-hover-bor-color:$primary-glass-green;

$btn-tertiary-bg-color:$primary-glass-green-100;
$btn-tertiary-txt-color:$secondary-metal-30;
$btn-tertiary-hover-bg-color:$primary-glass-green-300;

$btn-disabled-dark-bg-color:$secondary-metal-200;
$btn-disabled-light-bg-color:$secondary-metal-100;
$btn-disabled-dark-bor-color:$secondary-metal-400;
$btn-disabled-dark-txt-color:$secondary-metal;
$btn-disabled-light-txt-color:$secondary-metal;

//--Background Color---//////////////
$bg-primary-color: $primary-glass-green;
$bg-secondary-color: $primary-dark-blue;
$bg-blue-color:$secondary-blue;
$bg-grey-color: $secondary-metal-200;
$bg-white-color:$secondary-white;
$bg-dark-grey-color:$secondary-metal-30;
$bg-light-red-color:#FFDEDE;
$bg-light-green-color:#CCEDD1;
$bg-link-hover-color:#F4F4F4;
$bg-main-nav-bar-color:$secondary-metal-100;
$bg-link-hover-color: $secondary-metal-100;
$bg-row-color:rgba(142, 156, 156, 0.1);
$bg-footer:$primary-dark-blue;
$bg-light-grey-hover-color:#CECECE;
$bg-pagination-hover-color:#CECECE;
$bg-message-color:$secondary-metal-100;
$bg-dropdown-hover-color:$primary-glass-green-300;
$bg-green-color:$tertiary-green;
$bg-black-color:$secondary-steel;
$bg-light-blue:$secondary-blue-100;
$bg-very-light-grey-color:$secondary-metal-100;


$bg-selected-light:$secondary-blue-200;
$bg-hover:$primary-glass-green-200;
$bg-selected-dark:$secondary-blue;

//--Border Color---//////////////
$bor-primary-color:$primary-glass-green;
$bor-dark-color:$secondary-metal-30;
$bor-mid-grey-color:$secondary-metal-300;
$bor-very-light-grey-color:$secondary-metal-100;
$bor-blue-color:$secondary-blue;
$bor-white-color:$secondary-white;
$bor-red-color:#B21205;
$bor-green-color:$tertiary-green;
$bor-disabled-color:$secondary-metal-400;
$bor-black-color:$secondary-steel;
$bor-dark-grey-color:$secondary-metal;
$bor-light-grey-color:$secondary-metal-200;

//--Text Color---//////////////
$text-primary-color:$primary-glass-green;
$text-secondary-color: $primary-dark-blue;
$text-secondary-title-color: $primary-dark-blue;
$text-body-color:$secondary-steel;
$text-white-color:$secondary-white;
$text-grey-color: $secondary-metal;
$text-body-grey-color:$secondary-metal-30;
$text-dark-grey-color:$secondary-metal-70;
$text-light-grey-color:$secondary-metal-400;
$text-link-color:$secondary-blue;
$text-error-color:#B21205;
$text-success-color:#046032;
$text-nav-hover-color: #54BCE3;
$text-grey-link-color: #8E9C9C;
$text-calender-grey-color:#CECECE;
$text-warning-color:$tiny-yellow-warning;
$text-green-color:$tertiary-green;
$text-dark-green-color:$tertiary-dark-green;
$text-dark-red-color:$tertiary-red-30;
$text-red-color:$tertiary-red;
$text-yellow-color:$tiny-yellow-40;
$text-inreview-color:$tertiary-red;
$text-published-color:$tertiary-green;
$text-unpublished-color:$secondary-metal-70;
$text-close-close-color: $secondary-metal-30;
$text-close-close-hover-color: $secondary-blue;
$text-black: $black;

$icon-disabled-color:$secondary-metal-400;

//--Input Color---//////////////
$input-text-color:$secondary-steel;
$input-bg-color:$secondary-metal-100;
$input-bor-color:$secondary-metal;
$input-focus-bg-color:$secondary-white;
$input-hover-bor-color:$secondary-blue;

$input-bor-error-color:#B21205;

$input-disabled-text-color:$secondary-metal;
$input-disabled-bor-color:$secondary-metal-200;
$input-disabled-bg-color:$secondary-metal-200;

//--Label Color---//////////////
$bg-label-new:$secondary-blue-200;
$bg-label-update-available:$secondary-blue-200;
$bg-label-ready-to-use:#3455DB;
$bg-label-active: $primary-glass-green-200; //Active / Published
$bg-label-expired: $secondary-metal-200;
$bg-label-archived: $secondary-metal-200; //Archived / Expired / Inactive
$bg-label-updated:$secondary-blue-200;
$bg-label-passive:$primary-dark-blue;
$bg-label-coming-soon:$tiny-yellow-100;
$bg-label-draft:$tiny-yellow-100;
$bg-label-incomplete:$tertiary-red-100; //Incomplete / Draft
$bg-label-content-manager:$primary-glass-green-200;
$bg-label-active-campaign:$primary-glass-green-200;
$bg-label-review-completed:$secondary-blue-200;
$bg-top-link-hover-color:$primary-glass-green-200;
$bg-in-review:$tertiary-red-100;
$bg-published:$primary-glass-green-200;
$bg-unpublished:$secondary-metal-200;

//--CK Editor---//////////////
$ck-editor-bor-color: $light-border;
$ck-editor-bor-color1: $light-border1;
//--Typography----- 
/*Convertion Ration sp to rem for html */
$conversionRatioSpToRemWeb: math.div(1, 16); //! 0.0625;
$conversionRatioRemToPxWeb: 16.0;

// Scales
@function spToRem($sps) {
    @return $sps * $conversionRatioSpToRemWeb;
}

;

@function remToPx($rems) {
    @return $rems * $conversionRatioRemToPxWeb;
}

;

@function spacing($tracking, $px) {
    @return math.div($tracking, $px);
}

;

@mixin lrn-typo($size, $family, $weight, $spacing) {
    font-size: #{spToRem($size)}rem;
    font-family: $family;
    font-weight: $weight;
    letter-spacing: #{spacing($spacing, remToPx($size))}px;
}

// Font Family
// $font-family-regular: 'proxima_nova_regular',
// sans-serif;
// $font-family-semibold: 'proxima_nova_semibold',
// sans-serif;
// $font-family-bold: 'proxima_nova_bold',
// sans-serif;



$font-family-regular: var(--font-family-regular);
$font-family-semibold: var(--font-family-semibold);

:root{
    --font-family-regular: 'proxima_nova_regular';
    --font-family-semibold: 'proxima_nova_semibold';
}
 
body.jaJP {
    --font-family-regular: 'jp-meiryo';
    --font-family-semibold: 'jp-meiryo';
}


//h6, .l-h6 { @include lrn-typo(24, $font-family-semibold, .15);}

@mixin l-h1-txt {
    @include lrn-typo(64, $font-family-regular, 100, 1.5);
}

@mixin l-h2-txt {
    @include lrn-typo(40, $font-family-regular, 100, -.5);
}

@mixin l-h3-txt {
    @include lrn-typo(32, $font-family-regular, 100, 0);
}

@mixin l-h4-txt {
    @include lrn-typo(24, $font-family-semibold, 100, .25);
    line-height: normal;
}

@mixin l-h5-txt {
    @include lrn-typo(24, $font-family-regular, 100, .25);
    line-height: normal;
}

h1,
.l-h1 {
    @include l-h1-txt;
    color: $text-secondary-title-color;
}

h2,
.l-h2 {
    @include l-h2-txt;
}

h3,
.l-h3 {
    @include l-h3-txt;
    color: $text-secondary-title-color;
}

h4,
.l-h4 {
    @include l-h4-txt;
    margin-bottom: 0;
}

h5,
.l-h5 {
    @include l-h5-txt;
    color: $text-secondary-title-color;
    margin-bottom: 0
}

h5.l-h5 {
    .l-h5__span-color {
        color: $text-primary-color;
    }

    .l-h5__sub-title {
        color: $text-body-color;
    }
}

@mixin l-user-icon-font {
    font-size: 2.5rem
}

@mixin l-subtitle1-txt {
    @include lrn-typo(18, $font-family-semibold, 100, .15);
    line-height: 20px;
}

@mixin l-subtitle2-txt {
    @include lrn-typo(18, $font-family-regular, 100, .15);
    line-height: 20px;
}

@mixin l-body1-semibold-txt {
    @include lrn-typo(16, $font-family-semibold, 100, .15);
    line-height: normal;
}

@mixin l-body1-txt {
    @include lrn-typo(16, $font-family-regular, 100, .5);
    line-height: 1.25rem;
}

@mixin l-body2-txt {
    @include lrn-typo(14, $font-family-regular, 100, .5);
    line-height: normal;
}

@mixin l-button-txt {
    @include lrn-typo(16, $font-family-regular, 100, .08);
}

@mixin l-button-small-txt {
    @include lrn-typo(14, $font-family-regular, 100, .6);
}

@mixin l-overline-txt {
    @include lrn-typo(16, $font-family-semibold, 100, 0);
}

@mixin l-caption-txt {
    @include lrn-typo(14, $font-family-semibold, 100, 8.0);
}

@mixin l-label-txt {
    @include lrn-typo(14, $font-family-semibold, 100, 4.0);
    line-height: 14px;
}

@mixin l-navigation-regular-txt {
    @include lrn-typo(18, $font-family-regular, 100, 0.5);
}

@mixin l-navigation-semibold-txt {
    @include lrn-typo(18, $font-family-semibold, 100, 0.5);
}

@mixin l-number-regular-txt {
    @include lrn-typo(12, $font-family-regular, 100, 4.0);
}

@mixin l-number-semibold-txt {
    @include lrn-typo(12, $font-family-semibold, 100, 4.0);
}

.l-body1-txt {
    @include l-body1-txt;
}

.l-body1-semibold-txt {
    @include l-body1-semibold-txt;
}

.l-body2-txt {
    @include l-body2-txt;
}

.l-subtitle1-txt {
    @include l-subtitle1-txt;
}

.l-subtitle2-txt {
    @include l-subtitle2-txt;
}

.l-button-txt {
    @include l-button-txt;
}

.l-button-small-txt {
    @include l-button-small-txt;
}

.l-overline-txt {
    @include l-overline-txt;
}

.l-caption-txt {
    @include l-caption-txt;
}

.l-label-txt {
    @include l-label-txt;
}

.l-navigation-regular-txt {
    @include l-navigation-regular-txt;
}

.l-navigation-semibold-txt {
    @include l-navigation-semibold-txt;
}

.l-number-regular-txt {
    @include l-number-regular-txt;
}

.l-number-semibold-txt {
    @include l-number-semibold-txt;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin shadow-blur-10 {
    box-shadow: 0px 0px 10px rgba(0, 97, 164, 0.2);
}

//#0061A4
@mixin shadow-blur-40 {
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
}

//#000000
@mixin shadow-sharp-right-bottom {
    box-shadow: 2px 2px 6px rgba(27, 36, 43, 0.16);
}

//#1B242B
@mixin btn-shadow-right-bottom {
    box-shadow: 1px 1px 0px rgba(27, 36, 43, 0.25);
}

@mixin icons-hover-active-state {
    background: $bg-dropdown-hover-color;
    @include btn-shadow-right-bottom();
    @include border-radius(2px);
}

@mixin l-mar-b20 {
    margin-bottom: 20px;
}

@mixin l-mar-b30 {
    margin-bottom: 30px;
}

@mixin l-mar-b40 {
    margin-bottom: 40px;
}

@mixin l-mar-b60 {
    margin-bottom: 60px;
}

@mixin l-mar-b90 {
    margin-bottom: 90px;
}

@mixin l-mar-t60 {
    margin-top: 60px;
}

@mixin l-mar-tb60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

@mixin l-mar-tb30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

@mixin l-mar-tb20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

@mixin l-mar-t40b60 {
    margin-top: 40px;
    margin-bottom: 60px;
}

@mixin l-mar-t60b40 {
    margin-top: 60px;
    margin-bottom: 40px;
}

@mixin l-mar-t32b96 {
    margin-top: 32px;
    margin-bottom: 96px;
}

@mixin l-mar-t43b36 {
    margin-top: 43px;
    margin-bottom: 36px;
}

@mixin l-pad-tb13 {
    padding-top: 13px;
    padding-bottom: 13px;
}

@mixin l-pad-tb40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

// @media only screen and (max-width: 992px) {
//     h1, .l-h1 { @include lrn-typo(40, $font-family-regular, 100, -.5); color: $text-secondary-title-color;}
//     h5, .l-h5 { @include lrn-typo(16, $font-family-regular, 100, 0); color:$text-secondary-title-color;}
// }


.ani-spin {
    -webkit-animation: zmdi-spin 1.5s infinite linear;
    animation: zmdi-spin 1.5s infinite linear;
}

// @media only screen and (max-width: 1599px) {
//     html {
//         font-size: 15px !important; 

//        // h1, .l-h1 { @include lrn-typo(64, $font-family-regular, 100, -.5); color: $text-secondary-title-color;}
//     }
// }

@media only screen and (max-width: 767px) {
    html {
        font-size: 15px !important;
    }
}

@media only screen and (max-width: 575px) {
    html {
        font-size: 14px !important;

        //h1, .l-h1 { @include lrn-typo(64, $font-family-regular, 100, -.5); color: $text-secondary-title-color;}
    }
}
.l-dropdown {
  position: relative;

  &.disabled {
    pointer-events: none;
  }

  &.l-icon,
  &.l-btn {
    .l-dropdown__menu {
      min-width: 256px;
    }

    &.open {
      background: $bg-dropdown-hover-color;

      .material-icons-outlined {
        color: $text-body-grey-color;
      }
    }
  }

  &__no-data {
    @include l-body1-txt;
    color: $text-body-color;
    background-color: $bg-white-color;
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 1000;
    width: 100%;
    box-shadow: 2px 2px 6px rgba(27, 36, 43, 0.16);
    padding: 12px 16px;
  }

  &__click {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  &__menu {
    @include l-body1-txt;
    color: $text-body-color;
    background-color: $bg-white-color;
    position: absolute;
    top: 104%;
    left: 0;
    z-index: 56;
    display: none;
    float: left;
    min-width: 100%;
    width: 100%;
    box-shadow: 2px 2px 6px rgba(27, 36, 43, 0.16);
    // max-height: 242px;
    // overflow-y: auto;

    &__checklist {
      .l-small-checkbox-list {
        li {
          margin-bottom: 0 !important;
        }
      }
    }

    &--scroll {
      max-height: 175px;
      overflow-y: auto;
    }

    &--no-scroll {
      max-height: none;
      overflow-y: hidden;
    }

    &__list-scroll {
      max-height: 150px;
      overflow-y: auto;
    }

    &__li-pad0 {
      li {
        padding: 0 !important;

        .l-pad-wrap {
          padding: 12px 16px;
        }
      }
    }

    li {
      text-align: left;

      //CCM-4294
      @at-root body[dir="rtl"] .l-dropdown__menu li {
        text-align: right;
      }

      a {
        color: $text-body-color;
        padding: 12px 16px;
        display: flex; //inline-block
        // width: 100%;
        align-items: center;
        // text-transform: capitalize;

        .material-icons-outlined {
          color: $text-body-grey-color;
        }

        &:hover {
          background-color: $bg-hover;

          .material-icons-outlined {
            color: $text-link-color;
          }
        }

        &.l-dropdown-sub-menu {
          position: relative;

          .l-dropdown-sub-menu__option {
            display: none;
            position: absolute;
            top: 0;
            right: 100%;
            float: left;
            min-width: 240px;
            font-size: 14px;
            text-align: left;
            list-style: none;
            background-color: #fff;
            @include border-radius(0);
            padding: 0;
            border: 0;
            white-space: initial;
            white-space: normal;
            clear: both;
            line-height: normal;
            box-shadow: 2px 2px 6px rgba(27, 36, 43, 0.16);
            font-size: 1rem;

            a {
              white-space: normal;
              clear: both;
              cursor: pointer;
              display: block;
              line-height: normal;
              font-weight: normal;
              -webkit-transition: all 0.3s ease-out;
              transition: all 0.3s ease-out;

              &:hover {
                background-color: $bg-hover;
                -webkit-transition: all 0.2s ease-out;
                -moz-transition: all 0.2s ease-out;
                -ms-transition: all 0.2s ease-out;
                transition: all 0.2s ease-out;
              }
            }
          }

          &:hover {
            .l-dropdown-sub-menu__option {
              display: block;
            }
          }

          &::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            top: 15px;
            right: 15px;
            border-top: 2px solid #a9a9a9;
            border-right: 2px solid #a9a9a9;
            transform: rotate(54deg) skew(20deg);
            transform: rotate(45deg);
          }
        }
      }

      &.l-dropdown-tooltip {
        position: relative;

        &::before {
          display: none;
          background-color: $bg-black-color;
          @include l-body2-txt;
          @include border-radius(4px);
          color: $text-white-color;
          text-align: center;
          text-transform: none;
          padding: 5px 10px;
          position: absolute;
          bottom: 116%;
          left: 50%;
          opacity: 0;
          -webkit-transition: opacity .6s;
          transition: opacity .6s;
          cursor: default;
          width: 235px;
        }

        &::after {
          display: none;
          opacity: 0;
          -webkit-transition: opacity .6s;
          transition: opacity .6s;
          content: "";
          position: absolute;
          top: -7px;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: $bor-black-color transparent transparent transparent;
        }

        &--add-to-site-disabled::before {
          content: "This course is in our newest format and temporarily requires a manual process to be added to your Catalyst site. Please contact your Customer Success Manager to make this course available.";
          margin-left: -160px;
          width: 320px;

          @media only screen and (max-width: 767px) {
            margin-left: -190px;
          }
        }

        &--infographic-add-to-site-disabled::before {
          content: "Infographics can’t be added to Content Manager. Please download the PDF from this page";
          margin-left: -118px;
        }

        &:hover {

          &::after,
          &::before {
            display: block;
            opacity: 1;
          }
        }
      }

      &.disabled {
        a {
          background-color: $bg-grey-color;
          color: $text-grey-color;
          cursor: not-allowed;

          .material-icons-outlined {
            color: $text-grey-color !important;
          }

          &:hover {
            background-color: $bg-grey-color;
            color: $text-grey-color;

            .material-icons-outlined {
              color: $text-grey-color;
            }
          }
        }
      }

      &.active {
        a {
          background-color: $bg-selected-light;
        }

        &:hover {
          a {
            background-color: $bg-selected-light;
          }
        }
      }

      &.l-dropdown-lazy-loading-wrap {
        position: relative;
        height: 40px;
        padding: 0 !important;

        .operator-loader-wrap .circular {
          width: 50px;
          ;
        }
      }

      &.l-dropdown-text-loader {
        position: relative;

        &::after {
          position: absolute;
          content: attr(loadingTooltip);
          top: 0;
          width: 100%;
          background: #fff;
          text-align: center;
          color: #0277BD;
          height: 100%;
          padding-top: 11px;
        }
      }

      &.l-dropdown-icon-list {
        padding: 0 !important;

        .l-dropdown-icon-list--wrap {
          position: relative;
          padding: 12px 16px 12px 48px;

          .material-icons-outlined {
            position: absolute;
            left: 14px;
            top: 8px;
            color: $text-body-grey-color;
          }
        }
      }
    }

    &--icon {
      padding-bottom: 16px;
      li {
        a {
          margin: 0 16px 1px;

          .material-icons-outlined {
            padding: 0 10px 0 0;
            font-size: 20px;

            //CCM-3632
            @at-root body[dir="rtl"] .l-dropdown__menu--icon li a .material-icons-outlined {
              padding: 0 0 0 10px;
            }
          }

          &:hover .material-icons-outlined {
            color: $text-link-color !important;
          }
        }

        ////CCM-3776
        &:not(:last-child) a {
          box-shadow:  0px 1px 0px rgba(0, 0, 0, 0.25);
        }

        &.disabled  {
          a {
            color: $text-grey-color !important;
          }
          &:hover {
            a:hover .material-icons-outlined {
              color: $text-grey-color !important;
            }
          }
        }
      }
    }

    &--right {
      right: 0;
      left: auto;

      //CCM-3632
      @at-root body[dir="rtl"] .l-dropdown__menu--right {
        right: auto;
        left: 0;
      }
    }

    &--input {
      top: 48px !important;

      li {
        color: $text-body-color;
        padding: 12px 16px;
        display: inline-block;
        // text-transform: capitalize;
        width: 100% !important;
        max-width: 100% !important;
        margin-right: 0 !important;
        cursor: pointer !important;

        .material-icons-outlined {
          color: $text-body-grey-color;
        }

        &:hover {
          background-color: $bg-hover;

          .material-icons-outlined {
            color: $text-link-color;
          }
        }

        &.l-selected {
          background-color: $bg-selected-light;

          &:hover {
            background-color: $bg-selected-light !important;
          }
        }

        &.l-lazy-loading {
          position: relative;
          height: 50px;
          padding: 0 !important;
        }
      }
    }

    &__select-count,
    &__btn-panel {
      float: left;
      width: 100%;
      padding: 12px 16px;
    }

    &__select-count {
      border-bottom: 1px solid $bor-mid-grey-color;
    }

    &__btn-panel {
      border-top: 1px solid $bor-mid-grey-color;
      text-align: right;

      .l-btn {
        display: inline-block;
      }
    }

    &--max-height150 {
      max-height: 150px;
      overflow-y: auto;
    }

    &.l-show-loader {
      .l-dropdown-icon-list:last-child {
        position: relative;

        &::after {
          position: absolute;
          content: attr(loadingTooltip);
          top: 0;
          width: 100%;
          background: #fff;
          text-align: center;
          color: #0277BD;
          height: 100%;
          padding-top: 11px;
        }
      }
    }
  }

  .l-form-col {
    .l-form-col__icon {
      .l-text-box {
        // text-transform: capitalize;
        cursor: pointer !important;

        //CCM-3554
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .material-icons-outlined {
        transition: all 0.6s ease 0s;
        -ms-transition: all 0.6s ease 0s;
        -moz-transition: all 0.6s ease 0s;
        -webkit-transition: all 0.6s ease 0s;
      }
    }
  }

  &.open {
    z-index: 56;

    .l-dropdown__menu {
      display: block;
    }

    .l-form-col {
      .l-form-col__icon {
        .material-icons-outlined:not(.action-icon-search) {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
    }
  }

  &--only-icon {
    position: relative;

    .material-icons-outlined {
      margin-top: 5px;
      margin-right: 15px;
      cursor: pointer;
      transition: all 0.6s ease 0s;
      -ms-transition: all 0.6s ease 0s;
      -moz-transition: all 0.6s ease 0s;
      -webkit-transition: all 0.6s ease 0s;
    }

    &.open {
      .material-icons-outlined {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      .l-dropdown__menu {
        min-width: 175px;
      }
    }
  }

  &--multi-select {
    .l-dropdown__menu {
      max-height: none;
      overflow-y: hidden;

      .l-small-checkbox-list {
        max-height: 150px;
        overflow-y: auto;
        margin-bottom: 0 !important;

        li {
          padding: 0 !important;

          label {
            padding: 15px 15px 10px 45px !important;
            width: 100%;

            //CCM-3627
            @at-root body[dir="rtl"] .l-dropdown--multi-select .l-dropdown__menu .l-small-checkbox-list li label {
              padding: 15px 45px 10px 15px !important;
                .l-checkbox {
                  left: auto;
                  right: 14px;
                }
            }

            .l-course-title {
              margin-bottom: 3px;
              @include l-body1-semibold-txt;
              display: block;
            }

            .l-separator-pipe {
              position: relative;
              padding-right: 10px;
              margin-right: 5px;

              &::before {
                content: '|';
                position: absolute;
                right: 0;
              }

              //CCM-3638
              @at-root body[dir="rtl"] .l-dropdown--multi-select .l-dropdown__menu .l-small-checkbox-list li label .l-separator-pipe {
                padding-left: 10px;
                margin-left: 3px;
                padding-right: 0;
                margin-right: 0;

                &::before {
                  content: '';
                }

                &::after {
                  content: "|";
                  position: relative;
                  left: 0;
                  right: 7px;
                }
              }
            }

            .l-checkbox {
              top: 14px;
              left: 14px;
            }
          }
        }
      }
    }
  }

  .l-form-col {
    margin-bottom: 0 !important;
  }
}

.l-multi-to-single-dropdown-converter {
  .l-dropdown.l-dropdown--multi-select .l-dropdown__menu .l-small-checkbox-list li label {
    padding-left: 16px !important;

    .l-custom-checkbox {
      display: none !important;
    }
  }
}

.l-dropdown-scroll {
  .l-dropdown__menu {
    max-height: 150px;
    overflow-y: auto;
  }
}

//Bootstrap Dropdown Style used in Search Dropdown 
.dropdown {
  position: relative;
  width: 100%;

  &-menu {
    @include l-body1-txt;
    color: $text-body-color;
    background-color: $bg-white-color;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    min-width: 150px;
    float: left;
    box-shadow: 2px 2px 6px rgba(27, 36, 43, 0.16);
    max-height: 200px;
    overflow-y: auto;
    padding: 0;
    margin-top: 0;
    border: 0;
    @include border-radius(0);

    li {
      text-align: left;
      width: 100%;
      max-width: 100% !important;

      a {
        color: $text-body-color;
        padding: 12px 16px;
        display: inline-block;
        width: 100%;

        &:hover {
          background-color: $bg-hover;
        }
      }

      &.disabled {
        a {
          background-color: $bg-grey-color;
          color: $text-grey-color;
          cursor: not-allowed;

          &:hover {
            background-color: $bg-grey-color;
            color: $text-grey-color;
          }
        }
      }

      &.active {
        background-color: $bg-selected-light !important;

        &.selected {
          background-color: $bg-selected-light !important;
        }
      }
    }
  }
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  color: $text-body-color !important;
  background-color: $bg-selected-light !important;
}

.dropdown-menu>li>a {
  white-space: normal !important;
}

@media only screen and (max-width: 575px) {
  .l-dropdown {
    &__menu {
      li {
        a {
          &.l-dropdown-sub-menu {
            .l-dropdown-sub-menu__option {
              position: relative !important;
              top: 10px !important;
              right: auto !important;
              min-width: 256px !important;
              box-shadow: none !important;
              left: -48px !important;
            }

            &::after {
              transform: rotate(132deg) !important;
            }
          }
        }
      }
    }
  }

  .l-language-dropdown {
    .l-dropdown__menu {
      left: auto;
      right: 0;
    }
  }
}

//********************************
// ICON DROPDOWN MENU
//********************************
// <a class="l-icon l-icon--menu-right l-dropdown">
//     <span class="material-icons-outlined" aria-hidden="true" data-toggle="dropdown">more_vert</span>
//     <ul class="l-dropdown__menu l-dropdown__menu--icon l-dropdown__menu--right"> 
//         <ng-container *ngFor="let dropdownOption of courseDetails.dropdownItems">
//             <li *ngIf="!dropdownOption.hidden" [ngClass]="{'disabled':dropdownOption.disabled}">
//                 <a  title={{dropdownOption.htmlTitle}} (click)='onCourseTileClickEvent({event:"dropdown",dropdownDetail:dropdownOption,courseDetail:courseDetails})'>
//                     <span class={{dropdownOption.class}} aria-hidden="true" >{{dropdownOption.icon}}</span> {{dropdownOption.title}}
//                 </a>
//             </li>
//         </ng-container>
//     </ul>
// </a> 

//********************************
// BUTTON DROPDOWN MENU
//********************************
// <a class="l-btn l-btn--primary l-btn--only-icon l-dropdown" title="Languages">
//     <div class="l-dropdown__click" aria-hidden="true" data-toggle="dropdown"></div> //THIS ADDED FOR CLICK
//     <span class="l-btn__icon material-icons-outlined">language</span><span class="l-btn__icon material-icons-outlined">south</span>
//     <ul class="l-dropdown__menu"> 
//         <ng-container *ngFor="let language of courseDetails.languages">
//             <li>
//                 <a  title={{language}} (click)='onCourseTileClickEvent({event:"languageSelected",dropdownDetail:language,courseDetail:courseDetails})'>
//                     {{language}}
//                 </a>
//             </li>
//         </ng-container>
//     </ul>
// </a> 

//********************************
// SINGLE SELECT ARROW DROPDOWN WITH INPUT TEXTBOX
//********************************
//   <div class="l-dropdown" [ngClass]="{'open':isOpen}"> 
//   <div class="l-form-col">
//       <div class="l-form-col__icon" (click)="isOpen=!isOpen">
//           <input class="l-text-box" type="text"
//           readonly
//           [value]="selectedDisplayValue" /> 
//           <div class="l-form-col__icon__wrap" >
//               <span class="l-form-col__icon__wrap__action-icon material-icons-outlined">expand_more</span> 
//           </div>
//       </div> 
//   </div> 
//   <ul class="l-dropdown__menu l-dropdown__menu--input" role="listbox">
//     <li
//       role="option"
//       *ngFor="let dropdownValue of dropDownValues"
//       (click)="dropdownOptionSelected(dropdownValue)"
//       [ngClass]="{'l-selected':selectedDisplayValue==dropdownValue.selectedValueDisplayName}"> 
//       <span>{{dropdownValue.name}}</span>
//     </li>
//   </ul>
// </div>

//********************************
// MULTI SELECT DROPDOWN WITH LAZY LOADING
//********************************
//   <div class="l-dropdown l-dropdown--multi-select"  [ngClass]="{'open':loadingSuggestions.showList}"> 
//     <div class="l-form-col">
//         <div class="l-form-col__icon">
//             <input class="l-text-box" type="text"
//             placeholder="Select one or more users"> 
//             <div class="l-form-col__icon__wrap">
//                 <div class="operator-loader-wrap show-loader" *ngIf="firstLoader">
//                     <svg class="circular" viewBox="25 25 50 50">
//                         <circle class="path" cx="50" cy="50" r="10" fill="none" stroke-width="2" stroke="#014f91"/>
//                     </svg>
//                 </div>
//                 <span class="l-form-col__icon__wrap__action-icon material-icons-outlined" *ngIf="!showSearch">search</span>
//                 <span class="l-form-col__icon__wrap__action-icon material-icons-outlined" *ngIf="loadingSuggestions.showList" (click)="closeDropdown()">close</span>
//             </div>
//         </div> 
//     </div>
//     <div *ngIf="noDataFound" class="l-dropdown__no-data"> 
//          No users found 
//     </div>
//     <div class="l-dropdown__menu l-dropdown__menu--input l-dropdown__menu--no-scroll" role="listbox" *ngIf="listItems && listItems.length > 0"> 
//         <div class="l-dropdown__menu__select-count">
//                 ({{selectedListItems.length}} selected )
//         </div>  
//         <ul #scrollDiv (scroll)="onScroll($event)" class="l-small-checkbox-list">
//             <li *ngFor="let data of listItems">
//                 <label>
//                     <div class="l-custom-checkbox l-checkbox" (click)="checkboxClick(data)">
//                         <input type="checkbox" [checked]="data.checked" [disabled]="data.disabled">
//                         <label class="f-label"></label>
//                         <div class="l-ripple"></div>
//                     </div>
//                     <span class="l-course-title" *ngIf="data.name">{{data.name}}</span>
//                     <!-- <span class="l-course-catalog-id" *ngIf="data.id" [ngClass]="{'l-separator-pipe': data.id}">{{data.id}}</span> -->
//                     <span class="l-course-main-topic" *ngIf="data.email">{{data.email}}</span>                           
//                 </label>
//             </li>
//             <li class="l-dropdown__menu--lazy-loading" *ngIf="showLoader">
//                 <div class="operator-loader-wrap show-loader" >
//                     <svg class="circular" viewBox="25 25 50 50">
//                         <circle class="path" cx="50" cy="50" r="10" fill="none" stroke-width="2" stroke="#014F91"/>
//                     </svg>
//                 </div>
//             </li> 
//         </ul>
//         <div class="l-dropdown__menu__btn-panel" *ngIf="!isFeatured">
//             <button name="submit" class="l-btn l-btn--primary l-btn--small" type="button" (click)="doneSelectingListItems()">
//                 <span class="l-btn__txt">Done</span>
//             </button>
//         </div>
//     </div> 
// </div>

//MULTI SELECT DROPDOWN CONVERT INTO SINGLE SELECT CLASS with following class 
//.l-multi-to-single-dropdown-converter


//********************************
// SINGLE SELECT DROPDOWN WITH BOTTOM BUTTON
//********************************
// <div class="l-dropdown"  [ngClass]="{'open':loadingSuggestions.showList}"> 
//     <div class="l-form-col">
//         <div class="l-form-col__icon">
//             <input class="l-text-box" type="text"
//             placeholder="Type company name here" [(ngModel)]="searchText" (keyup.enter)="searchCompanyDatabase()" (input)="listenSearchCompany()" value={{companyName}} (click) ="searchCompany()"> <!--[(ngModel)]="loadingSuggestions.searchText" (input)="searchCompanyDatabase()" -->
//             <div class="l-form-col__icon__wrap">
//                 <div class="operator-loader-wrap show-loader" *ngIf="firstLoader">
//                     <svg class="circular" viewBox="25 25 50 50">
//                         <circle class="path" cx="50" cy="50" r="10" fill="none" stroke-width="2" stroke="#014F91"/>
//                     </svg>
//                 </div>
//                 <span class="l-form-col__icon__wrap__action-icon material-icons-outlined" *ngIf="!showSearch">search</span>
//                 <span class="l-form-col__icon__wrap__action-icon material-icons-outlined" *ngIf="loadingSuggestions.showList" (click)="closeDropdown()">close</span>
//             </div>
//         </div> 
//     </div>
//     <div class="l-dropdown__menu l-dropdown__menu--input l-dropdown__menu--no-scroll" role="listbox">  
//         <ul class="l-dropdown__menu__list-scroll" #scrollDiv *ngIf="companyAll.length > 0" (scroll)="onScroll($event)">
//             <li *ngFor="let company of companyAll">
//                 <div (click)="showCompanyData(company)">{{company.companyName}}</div> 
//             </li>
//             <li class="l-lazy-loading" *ngIf="showLoader">
//                 <div class="operator-loader-wrap show-loader" >
//                     <svg class="circular" viewBox="25 25 50 50">
//                         <circle class="path" cx="50" cy="50" r="10" fill="none" stroke-width="2" stroke="#014F91"/>
//                     </svg>
//                 </div>
//             </li> 
//         </ul> 
//         <div *ngIf = "dropdownType !== 'sharePlaylist'" class="l-dropdown__menu__btn-panel">
//             <button title="Cancel" class="l-btn l-btn--secondary l-btn--small l-btn--left-icon" type="button"  (click)="openCreateNewCompanyPopup()">
//                 <span class="l-btn__icon material-icons-outlined">add</span><span class="l-btn__txt">Add New Company</span>
//             </button>
//         </div> 
//     </div> 
// </div>
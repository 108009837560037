@keyframes bounceAlpha {
    0% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }

    25% {
        opacity: 0;
        transform: translateX(10px) scale(0.9);
    }

    26% {
        opacity: 0;
        transform: translateX(-10px) scale(0.9);
    }

    55% {
        opacity: 1;
        transform: translateX(0px) scale(1);
    }
}

.l-btn__icon--animation {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.l-btn {
    //display: inline-block; 
    display: flex;
    @include l-button-txt;
    @include border-radius(2px);
    @include btn-shadow-right-bottom;
    color: $text-white-color;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 2px solid transparent;
    //padding: 1rem; 
    padding: 0 1rem;
    line-height: 48px;
    transition: color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
    // text-transform: uppercase;
    height: 48px;
    cursor: pointer;
    letter-spacing: 0.08em;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    .l-btn__icon,
    &__txt {
        margin: auto;
    }

    &--full {
        width: 100%;

        &.l-btn__txt {
            margin: initial;
        }
    }

    &--only-icon {
        .l-btn__icon {
            line-height: 46px;

            &:nth-of-type(1) {
                margin-left: 0;
            }

            &:nth-of-type(2) {
                margin-left: 14px;
            }
        }
    }

    &--primary {
        background-color: $bg-primary-color;

        &:hover,
        &:focus {
            background-color: $btn-primary-hover-bg-color;
            color: $text-white-color !important;
        }

        &.disabled {
            background: $btn-disabled-dark-bg-color;
            color: $btn-disabled-light-txt-color !important;
            cursor: not-allowed;

            &:hover {
                background: $btn-disabled-dark-bg-color !important;
                color: $btn-disabled-light-txt-color !important;
            }
        }
    }

    &--secondary {
        color: $btn-secondary-txt-color;
        border-color: $btn-secondary-bor-color;
        background-color: $bg-white-color;

        &:hover,
        &:focus {
            color: $btn-secondary-hover-txt-color !important;
            border-color: $btn-secondary-hover-bor-color !important;
            background-color: $btn-secondary-hover-bg-color !important;
        }

        &.disabled {
            color: $btn-disabled-light-txt-color !important;
            border-color: $btn-disabled-dark-bor-color !important;
            background: $btn-disabled-light-bg-color !important;
            cursor: not-allowed;

            &:hover {
                color: $btn-disabled-light-txt-color !important;
                border-color: $btn-disabled-dark-bor-color !important;
                background: $btn-disabled-light-bg-color !important;
            }
        }
    }

    &--tertiary {
        color: $btn-tertiary-txt-color;
        background-color: $btn-tertiary-bg-color;

        &:hover,
        &:focus {
            color: $btn-tertiary-txt-color !important;
            background-color: $btn-tertiary-hover-bg-color;
        }

        &.disabled {
            background-color: $btn-disabled-light-bg-color !important;
            color: $btn-disabled-light-txt-color !important;
            cursor: not-allowed;

            &:hover {
                background-color: $btn-disabled-light-bg-color !important;
                color: $btn-disabled-light-txt-color !important;
            }
        }
    }

    &--text-box-edit-icon {
        border: 1px solid $input-bor-color;
        border-left-width: 0;
        background-color: $input-bg-color;
        color: $text-body-grey-color;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;

        &:hover,
        &:focus {
            color: $text-link-color !important;
            border-color: $input-hover-bor-color;
        }
    }

    // .l-btn__disabled-click{
    //     pointer-events: none;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     top:0;
    //     bottom: 0; 
    //     height: 100%;
    //     width: 100%;
    //     z-index: 1;
    // }
    // &.l-btn-tertiary{

    // }
    &--left-icon,
    &--right-icon {
        position: relative;

        .l-btn__icon {
            position: absolute;
            // top: 50%;
            // transform: translateY(-50%);
            top: 0;
            bottom: 0;
            margin: auto;
            height: 24px;

            &.l-btn__icon--backslash {
                width: 2px;
                height: 30px;
                border-left: 2px solid $bg-dark-grey-color;
                border-right: 2px solid $btn-tertiary-bg-color;
                position: absolute;
                left: 25px;
                top: 0;
                z-index: 1;
                transform: rotate(-45deg);
            }
        }
    }

    &--left-icon {
        padding-left: 3.375rem;

        .l-btn__icon {
            left: 1rem;
        }
    }

    &--right-icon {
        padding-right: 3.375rem;

        .l-btn__icon {
            right: 1rem;
        }
    }

    &--small {
        @include l-button-small-txt;
        padding: 0 0.75rem;
        line-height: 32px;
        height: 32px;

        .material-icons-outlined {
            font-size: 1rem;
        }

        &.l-btn--left-icon,
        &.l-btn--right-icon {
            .l-btn__icon {
                height: 16px !important;
            }
        }

        &.l-btn--left-icon {
            padding-left: 2.25rem;

            .l-btn__icon {
                left: 0.625rem;
            }
        }

        &.l-btn--right-icon {
            padding-right: 2.25rem;

            .l-btn__icon {
                right: 0.625rem;
            }
        }

        &.l-btn--only-icon {
            .l-btn__icon {
                line-height: 27px;
            }
        }
    }
}

.l-page-btn-panel {
    @include l-mar-t32b96;
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .l-btn {
        margin: 0 8px;
    }

    .l-txt-blue-link {
        margin-left: 8px;
    }
}

@media only screen and (max-width: 480px) {
    .l-page-btn-panel {
        flex-wrap: wrap;

        .l-btn {
            width: 100%;
            margin-bottom: 16px !important;
            margin-left: 0 !important;
            margin-right: 0 !important;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }

    // .l-btn {
    //     height: auto;
    //     line-height: 22px;
    //     padding-top: 8px;
    //     padding-bottom: 8px;
    // }

    .l-btn {
        &.l-btn--only-icon {
            height: 48px;
            line-height: 48px;
            padding-top: 0;
            padding-bottom: 0;
        }

        &--small {
            &.l-btn--only-icon {
                line-height: 32px;
                height: 32px;
                padding: 0 0.75rem;
            }
        }
    }
}



.l-btn-tooltip {
    position: relative;

    &::before {
        display: none;
        background-color: $bg-black-color;
        @include l-body2-txt;
        color: $text-white-color;
        text-align: center;
        text-transform: none;
        border-radius: 4px;
        padding: 5px 10px;
        position: absolute;
        bottom: 116%;
        left: 50%;
        opacity: 0;
        -webkit-transition: opacity .6s;
        transition: opacity .6s;
        cursor: default;
        width: 235px;
    }

    &::after {
        display: none;
        opacity: 0;
        -webkit-transition: opacity .6s;
        transition: opacity .6s;
        content: "";
        position: absolute;
        top: -7px;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $bor-black-color transparent transparent transparent;
    }

    &.l-tooltip-unpublish-all::before {
        content: attr(unPublishAllLibraryOrMainVersionsTooltip);
        margin-left: -118px;
    }

    &.l-tooltip-publish-all::before {
        width: 220px;
        content: attr(publishAllLibraryOrMainVersionsTooltip);
        margin-left: -110px;
    }

    &.l-tooltip-remove-site::before {
        width: 114px;
        content: attr(removeFromSiteTooltip);
        margin-left: -57px;
    }

    &.l-tooltip-add-to-site-disabled {
        cursor: not-allowed;

        &::before {
            content: attr(thisCourseIsInOurNewestFormatAndTemporarilyTooltip);
            margin-left: -160px;
            width: 320px;

            @media only screen and (max-width: 434px) {
                margin-left: -64px;
            }
        }



    }

    &.l-tooltip-info-graphic-add-to-site-disabled {
        cursor: not-allowed;

        &::before {
            content: "Infographics can’t be added to Content Manager. Please download the PDF from this page";
            margin-left: -118px;
        }
    }

    &:hover {

        &::after,
        &::before {
            display: block;
            opacity: 1;
        }
    }
}

//rtl css style
[dir="rtl"] {
    .l-btn {
        &--left-icon {
            padding-left: 1rem;
            padding-right: 3.375rem;
    
            .l-btn__icon {
                left:auto;
                right: 1rem;
            }
        }
    
        &--right-icon {
            padding-right: 1rem;
            padding-left: 3.375rem;
    
            .l-btn__icon {
                right: auto;
                left: 1rem;
            }
        }
 
        &--only-icon {
            .l-btn__icon {
                &:nth-of-type(1) {
                    margin-left: auto;
                    margin-right: 0;
                }
    
                &:nth-of-type(2) {
                    margin-left: auto;
                    margin-right: 14px;
                }
            }
        }
 
        &--small{
            &.l-btn--left-icon {
                padding-left: 0.75rem;
                padding-right: 2.25rem;
    
                .l-btn__icon {
                    left: auto;
                    right: 0.625rem;
                }
            }
    
            &.l-btn--right-icon {
                padding-right: 0.75rem;
                padding-left: 2.25rem;
    
                .l-btn__icon {
                    right: auto;
                    left: 0.625rem;
                }
            }
        }
    }

    //for icon flip
    .l-rtl-icon-flip{
            scale: -1 100%;
    }
}

//Use Following button structure
//*** Button without icon ***
// <button title="Cancel" class="l-btn l-btn--primary" type="button">
//     <span class="l-btn__txt">Cancel</span>
// </button>

//:*** Button with Right side icon ***
// <button title="Cancel" class="l-btn l-btn--primary l-btn--right-icon" type="button">
//     <span class="l-btn__icon material-icons-outlined">close</span><span class="l-btn__txt">Cancel</span>
// </button>

//*** Button with Left side icon ***
// <button title="Cancel" class="l-btn l-btn--primary l-btn--left-icon" type="button">
//     <span class="l-btn__icon material-icons-outlined">close</span><span class="l-btn__txt">Cancel</span>
// </button>

//*** Button with Only One icon ***
// <a class="l-btn l-btn--primary l-btn--only-icon" title="Cancel">
//     <span class="l-btn__icon material-icons-outlined">close</span>
// </a>

//*** Button with Only Two icon ***
// <button class="l-btn l-btn--primary l-btn--only-icon">
//     <span class="l-btn__icon material-icons-outlined">language</span><span class="l-btn__icon material-icons-outlined">south</span>
// </button> 


//*** SMALL BUTTON ***
// <button class="l-btn l-btn--small l-btn--primary l-btn--only-icon">
//     <span class="l-btn__icon material-icons-outlined">language</span><span class="l-btn__icon material-icons-outlined">south</span>
// </button> 

//*** BUTTON ICON ANIMATION ***
// <button class="l-btn l-btn--primary l-btn--right-icon" title="loginButtonText" type="submit">
//     <span class="l-btn__icon material-icons-outlined" [ngClass]="{'l-btn__icon--animation':loginProcessing}">arrow_forward</span><span class="l-btn__txt">loginButtonText</span>
// </button>
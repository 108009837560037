/* You can add global styles to this file, and also import other style files */
// @import 'assets/styles/global_variables';
// @import 'assets/styles/commons/reset';
// @import 'assets/styles/commons/common';
// @import 'assets/styles/commons/general';
// @import 'assets/styles/commons/custom';
// @import 'assets/styles/commons/buttons';
// @import 'assets/styles/commons/editor';
// @import 'assets/styles/commons/login';
// @import 'assets/styles/commons/neo';
// @import 'assets/styles/commons/checkboxes';
// @import 'assets/styles/commons/scrolling-tabs';


/* You can add global styles to this file, and also import other style files */
@import 'assets/styles/variables.scss';
@import 'assets/styles/bootstrap-reboot.css';
@import 'assets/styles/bootstrap-grid.css';
@import 'assets/styles/buttons.scss';
@import 'assets/styles/dropdown.scss';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important;
  font-size: 16px;
}

// body{
//   zoom: 0.9;
//   transform: scale(0.9);
//   transform-origin: 0 0;
//   -webkit-transform: scale(0.9);
//   -webkit-transform-origin: 0 0;
//   -moz-transform: scale(0.9);
//   -moz-transform-origin: 0 0;
//   -o-transform: scale(0.9);
//   -o-transform-origin: 0 0;
// }
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

button::-moz-focus-inner {
  border: 0;
}

input,
textbox {
  -webkit-appearance: none;
}

::-ms-clear {
  display: none;
}

button:focus,
a:focus,
input:focus,
a:active,
button:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#wrapper {
  min-height: 100%;
  position: relative;
  padding-bottom: 92px;
}

/* relative added for fix footer*/
@font-face {
  font-family: 'proxima_nova_regular';
  src: URL('assets/fonts/proxima-nova/proxima_nova_reg-webfont.woff2') format("woff2"), URL('assets/fonts/proxima-nova/proxima_nova_reg-webfont.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_nova_semibold';
  src: URL('assets/fonts/proxima-nova/proximanova-semibold-webfont.woff2') format("woff2"), URL('assets/fonts/proxima-nova/proximanova-semibold-webfont.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'jp-meiryo';
  src: URL('assets/fonts/jp-meiryo/meiryo.woff2') format("woff2"), URL('assets/fonts/jp-meiryo/meiryo.woff') format("woff"),
  URL('assets/fonts/jp-meiryo/meiryo.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'jp-meiryo-bold';
  src: URL('assets/fonts/jp-meiryo/meiryo-bold.woff2') format("woff2"), URL('assets/fonts/jp-meiryo/meiryo-bold.woff') format("woff"),
  URL('assets/fonts/jp-meiryo/meiryo-bold.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//     font-family: 'proxima_nova_bold';
//     src: url('assets/fonts/proxima-nova/proxima_nova_bold-webfont.woff2') format("woff2"), url('assets/fonts/proxima-nova/proxima_nova_bold-webfont.woff') format("woff");
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//   font-family: 'Material Icons Outlined';
//   font-style: normal;
//   font-weight: 400;
//   src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v54/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2) format('woff2');
// }

// @font-face {
//   font-family: 'Material Icons Outlined';
//   font-style: normal;
//   font-weight: 400;
//   src: url(assets/fonts/material-icons/MaterialIconsOutlined-Regular.eot); /* For IE6-8 */
//   src: local('Material Icons Outlined'),
//        local('MaterialIconsOutlined-Regular'),
//        url(assets/fonts/material-icons/MaterialIconsOutlined-Regular.woff2) format('woff2'),
//        url(assets/fonts/material-icons/MaterialIconsOutlined-Regular.woff) format('woff'),
//        url(assets/fonts/material-icons/MaterialIconsOutlined-Regular.ttf) format('truetype');
// }


// .material-icons-outlined {
//   font-family: 'Material Icons Outlined';
//   font-weight: normal;
//   font-style: normal;
//   font-size: 1.5rem;  /* Preferred icon size */
//   display: inline-block;
//   line-height: 1;
//   text-transform: none;
//   letter-spacing: normal;
//   word-wrap: normal;
//   white-space: nowrap;
//   direction: ltr;

//   /* Support for all WebKit browsers. */
//   -webkit-font-smoothing: antialiased;
//   /* Support for Safari and Chrome. */
//   text-rendering: optimizeLegibility;

//   /* Support for Firefox. */
//   -moz-osx-font-smoothing: grayscale;

//   /* Support for IE. */
//   font-feature-settings: 'liga';
// } 

.dropdown-backdrop {
  display: none !important;
}


body {
  @include l-body1-txt;
}

#toast-container .toast-success {
  background-color: $tertiary-green !important;
}

.disabled {
  cursor: not-allowed;
}

.l-msg-error {
  color: $tertiary-red;
  @include l-body2-txt;
}

input.l-text-box,
textarea.l-text-box,
.l-label-box-display {
  @include border-radius(2px);
  @include l-body1-txt;
  background-color: $input-bg-color;
  color: $input-text-color;
  border: 1px solid $input-bor-color;
  height: 48px;
  padding: 1rem;
  width: 100%;
  margin-bottom: 0;
  transition: border 150ms ease-out;
  cursor: text;

  &:focus,
  &:hover {
    outline: none !important;
    border-color: $input-hover-bor-color;
  }

  &:focus {
    background-color: $input-focus-bg-color;
  }

  &--error {
    border-color: $input-bor-error-color;

    &:focus,
    &:hover {
      border-color: $input-bor-error-color;
    }
  }

  &.disabled {
    color: $input-disabled-text-color;
    border-color: $input-disabled-bor-color;
    background-color: $input-disabled-bg-color;
    cursor: not-allowed;
  }
}

textarea.l-text-box {
  height: 96px;
}

.l-text-area {
  .l-label-box-display {
    height: auto !important;
    max-height: 96px;
    overflow-x: auto;
  }
}

.l-text-area-on-edit {
  .l-label-box-display {
    overflow-x: auto;
    padding-top: 5px;
    padding-bottom: 0;
  }
}

input.l-text-box--calender {
  background-image: url(./modules/catalyst/assets/images/icon-calender.svg);
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: right 10px top 11px;
}

.l-label-box-display {
  line-height: 17px;

  &:focus,
  &:hover {
    outline: none !important;
    border-color: $input-bor-color;
  }
}

.l-center-title-right-btn {
  color: $text-secondary-title-color;
  display: inline-block;
  min-height: 48px;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;

  .l-h5 {
    margin-top: 15px;
  }

  &__btn-wrap {
    position: absolute;
    right: 0;
    top: 0;
    width: 232px;
    text-align: right;

    .l-btn {
      margin-left: auto;
    }

    &--width292 {
      width: 292px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .l-center-title-right-btn {
    .l-h5 {
      margin-top: 0;
    }

    &__btn-wrap {
      position: relative;
      right: auto;
      top: auto;
      width: 100%;
      margin-bottom: 20px;

      .l-btn {
        margin: auto;
      }
    }
  }
}

.l-dot-list {
  list-style: none;
  margin-bottom: 15px;

  li {
    padding-left: 0;
    line-height: 22px;
    width: 96%;
    position: relative;
    list-style: none;
    padding-left: 17px;
    margin-bottom: 5px;

    &:after {
      background: $bg-primary-color;
      content: "";
      height: 6px;
      width: 6px;
      border-radius: 100%;
      display: inline-block;
      position: absolute;
      top: 7px;
      left: 0;
    }
  }

  &--metadata {
    padding-left: 10px;
  }
}

.l-form-row {
  .l-form-col {
    margin-bottom: 16px;
  }
}

.l-form-col {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 10px;

  &__icon {
    position: relative;

    .l-text-box {
      padding-right: 35px;
    }

    &__wrap {
      position: absolute;
      right: 0;
      top: 0;
      width: 54px;
      height: 48px;
      cursor: pointer !important;

      &__action-icon {
        position: absolute;
        right: 16px;
        top: 13px;

        .material-icons-outlined {
          color: $text-body-grey-color;
        }
      }

      &:hover {
        &__action-icon {
          color: $text-link-color;
        }
      }
    }
  }

  &__label {
    @include l-body1-txt;
    color: $text-body-grey-color;

    &--req-field {
      position: relative;
      padding-right: 7px;

      &::after {
        content: "*";
        font-size: 1.2rem;
        position: absolute;
        right: 0;
        top: -3px;
      }
    }
  }

  &--label-animated {
    margin-top: 25px;

    .l-form-col__label {
      position: absolute;
      left: 1rem;
      top: 1rem;
      z-index: 2;
      transition: all .2s ease;
      pointer-events: none;
    }
  }

  &--focused .l-form-col__label {
    top: -25px;
    left: 0;
  }

  &--error {
    .l-text-box {
      border-color: $input-bor-error-color;

      &:focus,
      &:hover {
        border-color: $input-bor-error-color;
      }
    }

    .l-form-col__error {
      color: $text-error-color;
      @include l-label-txt;
      padding: 5px 0px;
      display: inline-block;
      width: 100%;
    }
  }
}

.l-error-field {
  .l-text-box {
    border-color: $input-bor-error-color;

    &:focus,
    &:hover {
      border-color: $input-bor-error-color;
    }
  }

  .l-form-col__error {
    color: $text-error-color;
    @include l-label-txt;
    padding: 5px 0px;
    display: inline-block;
    width: 100%;
  }
}

.l-form-col__error:empty {
  display: none;
}

h5.l-h5 {
  .l-h5__sub-title {
    @include l-body1-txt;
    display: block;
    margin-top: 24px;
  }
}

.l-click-text {
  cursor: pointer !important;

  &:hover {
    text-decoration: underline;
  }
}

.l-display-none {
  display: none !important;
}


.l-display-inline-block {
  display: inline-block;
  width: 100%;
}

.l-w-100 {
  width: 100%;
}

.l-fl {
  float: left;
}

.l-pull-right {
  width: 100%;
  display: block;
  text-align: right;

  .l-btn {
    display: inline-block;
  }
}

.l-font-bold {
  font-weight: 700 !important;
}

.l-mar-top28minus {
  margin-top: -28px;
}

.l-mar-t0 {
  margin-top: 0 !important;
}

.l-mar-t3minus {
  margin-top: -3px;
}

.l-mar-t6 {
  margin-top: 6px !important;
}

.l-mar-t13 {
  margin-top: 13px;
}

.l-mar-t12 {
  margin-top: 13px;
}

.l-mar-t10 {
  margin-top: 10px;
}

.l-mar-t15 {
  margin-top: 15px !important;
}

.l-mar-t16 {
  margin-top: 16px;
}

.l-mar-t20 {
  margin-top: 20px;
}

.l-mar-t30 {
  margin-top: 30px !important;
}

.l-mar-t40 {
  margin-top: 40px;
}

.l-mar-t60 {
  margin-top: 60px;
}

.l-mar-t48 {
  margin-top: 48px !important;
}

.l-mar-b15 {
  margin-bottom: 15px !important;
}

@media only screen and (max-width: 480px) {
  .l-mar-t60 {
    margin-top: 30px;
  }

  .l-mar-t48 {
    margin-top: 25px !important;
  }

  .l-mar-t40 {
    margin-top: 20px !important;
  }
}

.l-mar-t96 {
  margin-top: 96px;
}

.l-mar-b0 {
  margin-bottom: 0 !important;

  .l-small-checkbox-list {
    margin-bottom: 0 !important;
  }
}

.l-mar-b10 {
  margin-bottom: 10px !important;
}

.l-mar-b16 {
  margin-bottom: 16px !important;
}

.l-mar-b20 {
  margin-bottom: 20px !important;
}

.l-mar-b25 {
  margin-bottom: 25px !important;
}

.l-mar-b30 {
  margin-bottom: 30px !important;
}

.l-mar-b40 {
  margin-bottom: 40px !important;
}

.l-mar-b48 {
  margin-bottom: 48px !important;
}

.l-mar-b50 {
  margin-bottom: 50px;
}

@media only screen and (max-width: 480px) {
  .l-mar-b50 {
    margin-top: 25px;
  }
}

.l-mar-b64 {
  margin-bottom: 64px !important;
}

.l-mar-l10 {
  margin-left: 10px;
}

.l-mar-l20 {
  margin-left: 20px;
}

.l-mar-l30 {
  margin-left: 30px;
}

.l-mar-r5 {
  margin-right: 5px !important;
}

.l-mar-r10 {
  margin-right: 10px;
}

.l-mar-r14 {
  margin-right: 14px;
}

.l-mar-tb30 {
  @include l-mar-tb30;
}

.l-pad-t0 {
  padding-top: 0 !important;
}

.l-pad-t16 {
  padding-top: 16px !important;
}

.l-pad-b0 {
  padding-bottom: 0 !important;
}

.l-pad-b16 {
  padding-bottom: 16px !important;
}

.l-pad-b25 {
  padding-bottom: 25px !important;
}

.l-pad-b30 {
  padding-bottom: 30px !important;
}

.l-pad-r40 {
  padding-right: 40px !important;
}

.l-pad-l5 {
  padding-left: 5px;
}

.l-pad-l28 {
  padding-left: 28px !important;
}

.l-pad-l140 {
  padding-left: 140px !important;
}

.l-pad-l155 {
  padding-left: 155px !important;
}

.l-pad-lr0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.l-pad-lr24 {
  padding-left: 24px;
  padding-right: 24px;
}

.boldText {
  font-weight: 600 !important;
}

.l-txt-align-center {
  text-align: center;
}

.l-xs-hide {
  display: block;
}

.l-xs-show {
  display: none;
}

@media only screen and (max-width: 575px) {
  .l-xs-show {
    display: block;
  }

  .l-xs-hide {
    display: none;
  }
}

.l-scroll-h112 {
  list-style: none;
  max-height: 112px;
  overflow-x: hidden;
  overflow-y: auto;
}

.l-content-section {
  display: inline-block;
  width: 100%;
  @include l-mar-tb30;

  h5.l-h5 {
    @include l-mar-b30;
  }

  .l-btn {
    display: inline-block;
  }

  .l-content-section__sub-title {
    @include l-subtitle1-txt;
    margin-bottom: 16px;
    color: $text-body-color;
  }
}

.l-overlap-icon {
  position: absolute;
  left: 14px;
  top: 11px;
  font-size: 16px !important;
  background-color: $bg-white-color;
  border-radius: 50%;
  padding: 0;
}

.l-grey-bg-section {
  background: $bg-grey-color;
  @include l-pad-tb13;

  //&--full{
  //@include l-pad-tb13;
  // margin-right: -15px;
  // margin-left: -15px;
  //}
}

.l-blue-gradient-bg-section {
  background: $element-gradient;
  @include l-h5-txt;
  color: $text-white-color;

  &__col {
    padding: 30px 48px;

    .l-h4 {
      &--icon {
        position: relative;
        padding-left: 49px;
        margin-bottom: 20px;

        .material-icons-outlined {
          position: absolute;
          left: 0;
          top: 2px;
        }
      }
    }

    &__btn-panel {
      width: 100%;
      display: inline-block;
      margin-top: 30px;

      .l-btn.l-btn--secondary {
        color: $text-white-color;
        border-color: $bor-white-color;
        background-color: transparent;

        &:hover,
        &:focus {
          color: $bg-secondary-color !important;
          border-color: $bor-white-color !important;
          background-color: $bg-white-color !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .l-blue-gradient-bg-section {
    &__col {
      padding: 38px;

      .l-h4 {
        &--icon {
          margin-bottom: 25px;
        }
      }

      &__btn-panel {
        margin-top: 25px;
      }
    }
  }
}

.l-txt-grey-color {
  color: $text-body-grey-color !important;
}

.l-txt-primary-color {
  color: $text-primary-color;
}

.l-txt-body-color {
  color: $text-body-color;
}

.l-txt-secondary-color {
  color: $text-secondary-title-color;
}

.l-txt-grey-link {
  @include l-body1-semibold-txt;
  @include border-radius(8px);
  color: $text-body-grey-color;
  padding: 8px 16px;

  &:hover {
    color: $text-link-color;
    background: $bg-link-hover-color;
    text-decoration: none;
  }
}

.l-txt-blue-link {
  @include l-body1-txt;
  color: $text-link-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline !important;
    color: $text-secondary-color;
  }

  &.disabled {
    cursor: not-allowed !important;
    color: $text-grey-color;

    &:hover {
      color: $text-grey-color !important;
      text-decoration: none !important;
    }
  }
}

.l-txt-uppercase-link {
  @include l-subtitle2-txt;
  @include border-radius(8px);
  color: $text-grey-link-color;
  text-decoration: underline;
  text-transform: uppercase;
  padding: 8px 16px;

  &:hover,
  &:focus {
    color: $text-link-color;
    text-decoration: underline;
    background: $bg-link-hover-color;
  }
}

.l-admin-cart {
  &__title {
    @include l-subtitle1-txt;
    color: $text-secondary-title-color;
    display: block;
  }
}

.l-playlist-icon-tooptip {
  position: absolute;
  top: 3px;
  left: 10px;
  width: 23px;
  height: 21px;

  .l-three-card-title-icon {
    top: 0 !important;
    left: 0 !important;
  }

  &::before {
    display: none;
    background-color: $bg-black-color;
    @include l-body2-txt;
    color: $text-white-color;
    text-align: center;
    border-radius: 4px;
    padding: 5px 10px;
    position: absolute;
    bottom: 142%;
    left: 50%;
    opacity: 0;
    -webkit-transition: opacity .6s;
    transition: opacity .6s;
    cursor: default;
  }

  &::after {
    display: none;
    opacity: 0;
    content: "";
    position: absolute;
    top: -9px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $bor-black-color transparent transparent transparent;
  }

  &.l-palylist-icon-lrn-tooltip::before {
    content: attr(lrnPlaylistTooltip);
    min-width: 92px; //CCM-3597 width: 92px;
    margin-left: -46px;
    max-width: 200px; //CCM-3597 
  }

  &.l-palylist-icon-mylist-tooltip::before {
    content: attr(lrnPlaylistTooltip);
    min-width: 90px; //CCM-3597 width: 90px
    margin-left: -45px;
    max-width: 200px; //CCM-3597 
  }

  &.l-palylist-icon-shared-tooltip::before {
    content: attr(lrnPlaylistTooltip);
    min-width: 110px; //CCM-3597 width: 110px;
    margin-left: -55px;
    max-width: 200px; //CCM-3597 
  }

  &:hover {

    &::after,
    &::before {
      display: block;
      opacity: 1;
      word-break: normal; //CCM-3597
    }
  }
}

@media only screen and (max-width : 1200px) {
  .l-playlist-icon-tooptip {
    &.l-palylist-icon-lrn-tooltip::before {
      margin-left: -21px;
    }

    &.l-palylist-icon-mylist-tooltip::before {
      margin-left: -21px;
    }

    &.l-palylist-icon-shared-tooltip::before {
      margin-left: -21px;
    }
  }
}

.l-css-icon-catalog {
  display: inline-block;
  width: 16px;
  height: 16px;
  // background-color:  $btn-primary-bg-color; 
  color: $text-white-color;
  border-radius: 2px;
  position: absolute;

  &::before {
    position: absolute;
    top: -5px;
    left: 3px;
    font-style: normal;
  }

  &::after {
    content: '';
    position: absolute;
    // border-left:2px solid $btn-secondary-bor-color;
    // border-bottom: 2px solid $btn-secondary-bor-color;
    border-bottom-left-radius: 2px;
    left: -4px;
    bottom: -4px;
    width: 16px;
    height: 16px;
  }

  &.l-add::before {
    content: '+';
    font-size: 16px;
    font-weight: bold;
    top: 0px;
    left: 4px;
  }

  &.l-remove::before {
    content: '−';
    font-size: 14px;
    font-weight: bold;
    top: -1px;
    left: 4px;
  }
}

.l-highlight {
  background: $bg-selected-light;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a,
a:active,
a:focus,
a:hover,
a.active,
a.active:focus,
a.active:hover {
  text-decoration: none;
}

.l-message {
  position: relative;
  @include l-body1-txt;
  padding: 16px 24px 16px 84px;
  @include border-radius(2px);
  width: 100%;
  margin-bottom: 20px;

  .l-message__icon {
    position: absolute;
    top: 12px;
    left: 24px;
  }

  &--success {
    background: $bg-light-green-color;
    color: $text-success-color;

    a.l-txt-blue-link:hover {
      color: $text-secondary-color !important;
      text-decoration: underline !important;
    }
  }

  &--error {
    background: $bg-light-red-color;
    color: $text-error-color;

    a.l-txt-blue-link:hover {
      color: $text-secondary-color !important;
      text-decoration: underline !important;
    }
  }
}

.l-error-txt {
  //replicat of l-form__col__error
  color: $text-error-color;
  @include l-label-txt;
  padding: 5px 0px;
  display: inline-block;
  width: 100%;
  visibility: visible;
}

.l-error-box {
  .l-label-box-display {
    background: $bg-light-red-color;
    border-color: $input-bor-error-color;
  }
}

.l-icon-message {
  @include l-body1-txt;
  color: $text-body-grey-color;
  position: relative;
  float: left;
  width: 100%;
  padding-left: 30px;

  .material-icons-outlined {
    position: absolute;
    top: -3px;
    left: 0;
  }
}


.l-para {
  @include l-body1-txt;
}

.l-bg-blue-wrap {
  background-color: $bg-light-blue;
  position: relative;
  float: left;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.l-back-top {
  opacity: 0;
  position: fixed;
  bottom: 30px;
  right: 50%;
  margin-right: -77px;
  z-index: 57;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

@media only screen and (max-width: 575px) {
  .l-back-top {
    margin-right: -28px;
  }
}

.l-search-btn-row {
  padding-right: 100px !important;
  position: relative;

  .l-form-col {
    margin-bottom: 0;
  }

  .l-btn {
    position: absolute;
    right: 15px;
    top: 0;
  }
}

.l-110-width {
  width: 110px;
  float: right;
  text-align: right;

}

.l-110-margin-right {
  margin-right: 110px;
  word-break: break-all;
}


.l-w-500 {
  float: left;
  max-width: 500px;
  width: 100%;
}

.l-label-input {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  &__label-110 {
    width: 110px;
    margin-top: 15px;
    color: $text-body-color;
  }

  &__input-wrap {
    flex: 1;
  }
}

.l-circle-filled {
  background: $text-body-grey-color;
  border-radius: 50%;
  padding: 1px;

  &.material-icons-outlined {
    font-size: 19px;
    color: $text-white-color !important;
  }
}

.l-info-row {
  @include l-subtitle2-txt;
  color: $text-grey-color;
  display: inline-flex;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 36px;
  width: 100%;

  &__label {
    width: 150px;
    margin-right: 20px;

    &--title {
      color: $text-secondary-title-color;
    }
  }

  &__label65 {
    width: 70px;
    margin-right: 20px;
  }

  &__fix100 {
    width: 100px;
    height: 100px;
    display: flex;
    background: $steel-gradient;
    @include border-radius(10px);
    text-align: center;

    .material-icons-outlined {
      font-size: 40px;
      // justify-self: center;
      align-self: center;
      margin: auto;
      color: $text-secondary-color;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    .l-svg-icon {
      width: 40px;
      height: 40px;
      // justify-self: center;
      align-self: center;
      margin: auto;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      path {
        fill: $text-secondary-color;
      }
    }
  }

  &__flexible {
    flex: 1;
    padding-left: 16px;

    .l-h5 {
      color: $text-secondary-color;
    }

    p {
      @include l-body1-txt;
      line-height: 18px;
      color: $text-body-color;
      margin-bottom: 0;
    }
  }

  &__info {
    -ms-flex: 1;
    flex: 1;
    color: $text-secondary-color;
  }

  &--zoom-icon {
    cursor: pointer;

    &:hover {
      .l-info-row__fix100 {
        .material-icons-outlined {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
          color: $text-primary-color;
        }

        .l-svg-icon {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);

          path {
            fill: $text-primary-color;
          }
        }
      }
    }
  }

  &--column {
    flex-direction: column;

    .l-info-row__label {
      width: 100%;
      margin-right: 0;
    }

    .l-info-row__info {
      width: 100%;
      word-break: break-word;
      text-align: left;

      .l-small-checkbox-list {
        padding-right: 30px !important;

        li label .l-checkbox {
          top: 12px;
          right: 0;
          left: auto;
        }
      }
    }
  }
}

.l-help-text {
  @include l-body2-txt;
  color: $text-grey-color;
  display: inline-block;
  width: 100%;
}

/* Password Eye Start*/
.l-right-password:after {
  border-right: 2px solid green;
  border-bottom: 2px solid green;
  border-top: 0;
  border-left: 0;
  top: 13px;
  right: 60px;
  width: 8px;
  height: 18px;
  position: absolute;
  display: block;
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 4;
}

/* Password Eye End*/
.l-page-title {
  text-align: center;
  margin-bottom: 0;
  color: $text-secondary-title-color;

  &.l-page-title--bor::before,
  .l-page-title__info::before {
    content: '';
    width: 72px;
    height: 4px;
    position: absolute;
    left: 50%;
    margin-left: -36px;
    background: $hero-gradient;
  }

  &--bor {
    position: relative;
    padding-bottom: 20px;

    &::before {
      bottom: 0;
    }
  }

  &--fix-btn {
    position: relative;

    &__wrap {
      position: absolute;
      right: 0;
      top: 0;
      text-align: right;

      .l-btn {
        margin-left: auto;
      }
    }
  }

  &__info {
    position: relative;
    display: inline-block;
    @include l-body1-txt;
    line-height: 32px;
    color: $text-body-color;
    padding-top: 25px;
    margin-top: 20px;
    width: 100%;

    &::before {
      top: 0;
    }
  }

  .l-flag {
    position: absolute;
    top: 12px;
  }

  @at-root .l-h3.l-page-title .l-page-title__info {
    @include l-h5-txt;
    color: $text-secondary-color;

    &.l-page-title__info--small-text {
      @include l-body1-txt;
      color: $text-body-color;
      width: 80%;

      span {
        @include l-body1-semibold-txt;
      }
    }
  }
}

.l-h4 {
  span.l-sub-title {
    @include l-body1-txt;
    color: $text-body-color;
    width: 80%;
    display: block;
    margin: 16px auto 0;
  }

  .l-btn.l-btn--primary span.l-btn__txt {
    color: $text-white-color;
  }
}

.l-h5 {
  .l-btn.l-btn--primary span.l-btn__txt {
    color: $text-white-color;
  }
}

@media only screen and (max-width: 991px) {
  .l-page-title--fix-btn__wrap {
    position: relative;
    right: auto;
    top: auto;
    width: auto;
    text-align: center;
    margin-top: 30px;
  }
}


.l-xl-show {
  display: none;
}

.l-lg-show {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .l-xl-hide {
    display: none !important;
  }

  .l-xl-show {
    display: block !important;
  }
}

@media only screen and (max-width: 991px) {
  .l-lg-hide {
    display: none !important;
  }

  .l-lg-show {
    display: block;
  }
}

.l-grey-bg-section {
  .l-page-title {
    &--bor {
      padding-bottom: 20px;

      .l-h1,
      .l-h5 {
        margin-bottom: 0;
      }
    }
  }
}

.l-page-back {
  position: relative;
  margin-bottom: 30px;

  &--fix {
    position: absolute;
    top: 0;
    left: 15px;
    z-index: 1;
    margin-bottom: 0;
  }

  .l-btn {
    display: inline-block;
  }
}

.l-block {
  display: block;
}

.l-container-top-pad {
  padding-top: 120px;
}

.l-container-top-pad-cm {
  padding-top: 120px;
}

.l-page-top-pad {
  padding-top: 25px;
}

.l-container-top-pad-no-nav {
  padding-top: 40px;
}

.l-filter-summary-list {
  float: left;
  width: auto !important;

  li {
    display: inline-block;
    margin: 0 16px 8px 0 !important;

    @at-root body[dir="rtl"] .l-filter-summary-list li {
      margin: 0 0 8px 16px !important;
    }

    .l-filter-summary-list__point {
      position: relative;
      @include l-label-txt;
      @include border-radius(2px);
      background-color: $bg-white-color;
      color: $text-body-grey-color;
      border: 1px solid $bor-disabled-color;
      cursor: default;
      padding: 5px 26px 5px 10px;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;

      @at-root body[dir="rtl"] .l-filter-summary-list li .l-filter-summary-list__point  {
        padding: 5px 10px 5px 26px;
      }

      &__remove {
        position: absolute;
        top: 4px;
        right: 4px;
        width: 18px;
        height: 20px;
        z-index: 1;
        text-align: center;
        cursor: pointer;

        @at-root body[dir="rtl"] .l-filter-summary-list li .l-filter-summary-list__point__remove {
            left: 4px;
            right: auto;
        }

        .material-icons-outlined {
          font-size: 1rem;
          color: $text-body-grey-color;
          vertical-align: middle;
        }
      }

      &:hover {
        border-color: $bor-blue-color;
      }
    }
  }

  &__topic {
    li {
      .l-filter-summary-list__point {
        @include l-caption-txt;
        color: $text-body-color !important;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .l-filter-summary-list li {
    width: auto !important;
  }
}


.l-alphabetical-filter {
  @include shadow-blur-10;
  width: 100%;
  float: left;
  padding: 10px 0;

  &__title {
    @include l-subtitle1-txt;
    color: $text-secondary-color;
    position: relative;
    padding: 7px 0 10px 15px;
    display: inline-block;
    margin-top: 10px;
  }

  ul.l-alphabetical-filter__list {
    list-style: none;

    li {
      padding: 0 4px;
      margin: 10px 14px;
      float: left;
      position: relative;
      color: $text-grey-color;
      @include l-body1-txt;
      cursor: pointer;

      &.l-alphabetical-filter__list__active {
        color: $text-secondary-color;
        @include l-body1-semibold-txt;

        &:before {
          visibility: visible;
          transform: scaleX(1);
          -webkit-transform: scaleX(1);
          -moz-transform: scaleX(1);
          -ms-transform: scaleX(1);
          -o-transition: scaleX(1);
        }
      }

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -1px;
        left: 0;
        background-color: $bg-secondary-color;
        visibility: hidden;
        transform: scaleX(0);
        -webkit-transform: scaleX(0);
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
      }

      &:hover {
        color: $text-secondary-color;

        &:before {
          visibility: visible;
          transform: scaleX(1);
          -webkit-transform: scaleX(1);
          -moz-transform: scaleX(1);
          -ms-transform: scaleX(1);
          -o-transition: scaleX(1);
        }
      }
    }
  }
}

// CCM-3593
.l-msg-container {
	justify-content: center;
  font-family: $font-family-regular;
	
	.l-msg {
	  padding: 0px 8px 0px 8px;
	  border-radius: 2px;
	  min-height: 48px;
	  display: flex;
	  align-items: center;
  
	  span {
		vertical-align: middle;

      &.break {
        display: block;
      }

      &.bold {
        font-family: $font-family-semibold;
      }
  
      &.l-alertIcon,
      &.alertIcon {
        padding: 12px;
      }
    
      &.l-msg-txt {
        padding: 10px;
        font-weight: 400;
      }
	  }

    &.l-msg-error {
      background-color: #F8E6E6;

      span {
        color: #B50000;
      }
    }

    &.l-msg-info {
      background-color: #F2F7FB;

      span {
        color: #1B242B;

        &.alertIcon {
          color: #0061a4;
        }
      }

      .l-msg-close {
        margin-left: auto;
      }

      .text-link,
      .text-link span {
        color: $text-link-color;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.l-msg-inreview {
      background: $bg-in-review;
      padding: 0px 20px;
      margin: 0 -20px;
      
      span {
        color: $text-inreview-color;
      }
    }

    &-close {
      border: 0;
      background-color: transparent;
      color: $text-close-close-color;
  
      &:hover,
      &:hover span.material-icons-outlined {
        color: $text-close-close-hover-color;
      }
    }
	}

  .l-btn {
    display: inline-flex;
    margin: 0 5px;

    &.l-btn--small {
      line-height: 31px;
    }

    &--secondary {
      span.l-btn__txt {
        color: $btn-secondary-txt-color;
      }
    }
  }
}

// Circle Loader START
.l-lazy-loading-wrap {
  float: left;
  width: 100%;
  text-align: center;
}

.l-lazy-loading {
  position: relative;
  margin: 0 auto 0;
  width: 100px;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.l-loader-message {
  padding-top: 70px;
  position: relative;
  text-align: center;

  .l-loader-message-loader {
    position: absolute;
    height: 70px;
    width: 70px;
    top: 0;
    left: 50%;
    margin-left: -35px;
  }
}

.operator-loader-wrap {
  display: none;

  &.show-loader {
    display: block;
  }

  .loader-mask {
    position: absolute;
    z-index: 51;
    background-color: $secondary-white;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    /* Modern Browsers */
    opacity: 0.8;
    opacity: 0.8;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
}

.loader-wrap {
  position: relative;

  .loader-mask {
    z-index: 3;
  }
}

.showbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 52;

  .loader {
    position: relative;
    margin: 0 auto 0;
    width: 100px;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }
}

.circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  .path {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
}

.l-filter-loader {
  .operator-loader-wrap {
    .loader-mask {
      opacity: 0.4;
    }

    .showbox {
      top: 0;

      .loader {
        top: 5%;
      }
    }
  }
}

.l-page-loader .showbox {
  top: 200px;
}

.l-loader {
  display: none;

  &--show {
    display: block;
  }

  .l-loader__circle {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 5;

    .l-loader__circle__path {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -10;
      -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }
  }

  .l-loader__mask {
    position: absolute;
    z-index: 4;
    background-color: $bg-very-light-grey-color;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    /* Modern Browsers */
    opacity: 0.8;
  }
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@-webkit-keyframes color {
  0% {
    stroke: $primary-dark-blue;
  }

  40% {
    stroke: $secondary-blue;
  }

  66% {
    stroke: $primary-glass-green;
  }

  80%,
  90% {
    stroke: $primary-glass-green;
  }
}

@keyframes color {
  0% {
    stroke: $primary-dark-blue;
  }

  40% {
    stroke: $secondary-blue;
  }

  66% {
    stroke: $primary-glass-green;
  }

  80%,
  90% {
    stroke: $primary-glass-green;
  }
}

@-webkit-keyframes primaryColor {
  0% {
    stroke: $primary-glass-green;
  }

  40% {
    stroke: $primary-glass-green;
  }

  66% {
    stroke: $primary-glass-green;
  }

  80%,
  90% {
    stroke: $primary-glass-green;
  }
}

@keyframes primaryColor {
  0% {
    stroke: $primary-glass-green;
  }

  40% {
    stroke: $primary-glass-green;
  }

  66% {
    stroke: $primary-glass-green;
  }

  80%,
  90% {
    stroke: $primary-glass-green;
  }
}

// Circle Loader END

//Label START 
// @mixin l-flag {
//     width: 90px;
//     height: 24px;
//     position: relative;
//     box-shadow: 0px 2px 4px #00000029;
//     @include l-label-txt;
//     color: $text-white-color;
//     text-align: center;
//     padding-top: 3px;

//     &::after{ 
//       content: "";
//       position: absolute;
//       width: 0;
//       height: 0;
//       top:0;
//       right: -24px;
//       border-width: 12px;
//       border-style: solid;
//       border-right-color: transparent;
//     }
// }
@mixin l-flag {
  @include l-label-txt;
  @include border-radius(2px);
  color: $text-white-color;
  height: 24px;
  position: relative;
  padding: 5px 10px 0;
  display: inline-block;
}

.l-flag {
  @include l-flag;

  &--icon-left {
    position: relative;
    padding-top: 5px !important;
    padding-left: 26px !important;
    margin-top: -7px;
    margin-left: 15px;
    vertical-align: middle;

    &__icon {
      position: absolute;
      top: 4px;
      left: 6px;
      font-size: 1rem !important;
    }
  }

  &--icon-right {
    position: relative;
    padding-top: 5px !important;
    padding-right: 26px !important;
    vertical-align: middle;

    &__icon {
      position: absolute;
      top: 4px;
      right: 6px;
      font-size: 1rem !important;

      &--info {
        background-color: #00A59C;
        top: 0;
        width: 21px;
        right: 0;
        height: 100%;
        text-align: center;
        padding-top: 4px;
        border-radius: 0 2px 2px 0;

        &.material-icons-outlined {
          color: $text-white-color;
        }
      }
    }
  }

  &--incomplete {
    @include l-flag;
    background: $bg-label-incomplete;
    color: $text-red-color;
    // &::after{
    //   border-top-color: $bg-label-incomplete;
    //   border-bottom-color: $bg-label-incomplete; 
    //   border-left-color: $bg-label-incomplete;
    // }
  }

  &--new {
    @include l-flag;
    background: $bg-label-new;
    color: $text-link-color;
    // &::after{
    //   border-top-color: $bg-label-new;
    //   border-bottom-color: $bg-label-new; 
    //   border-left-color: $bg-label-new;
    // }
  }

  &--active {
    @include l-flag;
    background: $bg-label-active;
    color: $text-green-color;
    // &::after{
    //   border-top-color: $bg-label-active;
    //   border-bottom-color: $bg-label-active; 
    //   border-left-color: $bg-label-active;
    // }
  }

  &--expired {
    //expired and archived 
    @include l-flag;
    background: $bg-label-expired;
    color: $text-dark-grey-color;
    // &::after{
    //   border-top-color: $bg-label-expired;
    //   border-bottom-color: $bg-label-expired; 
    //   border-left-color: $bg-label-expired;
    // }
  }

  &--archived {
    //expired and archived 
    @include l-flag;
    background: $bg-label-archived;
    color: $text-dark-grey-color;
    // &::after{
    //   border-top-color: $bg-label-archived;
    //   border-bottom-color: $bg-label-archived; 
    //   border-left-color: $bg-label-archived;
    // }
  }

  &--updated {
    //update and passive  
    @include l-flag;
    background: $bg-label-updated;
    color: $text-link-color;
    // &::after{
    //   border-top-color: $bg-label-updated;
    //   border-bottom-color: $bg-label-updated; 
    //   border-left-color: $bg-label-updated;
    // }
  }

  &--passive {
    //update and passive  
    @include l-flag;
    background: $bg-label-updated;
    color: $text-link-color;
    // &::after{
    //   border-top-color: $bg-label-updated;
    //   border-bottom-color: $bg-label-updated; 
    //   border-left-color: $bg-label-updated;
    // }
  }

  &--update-available {
    //update and passive  
    @include l-flag;
    background: $bg-label-update-available;
    color: $text-link-color;
    // &::after{
    //   border-top-color: $bg-label-update-available;
    //   border-bottom-color: $bg-label-update-available; 
    //   border-left-color: $bg-label-update-available;
    // }
  }

  &--coming-soon {
    @include l-flag;
    background: $bg-label-coming-soon;
    color: $text-yellow-color;
    // &::after{
    //   border-top-color: $bg-label-coming-soon;
    //   border-bottom-color: $bg-label-coming-soon; 
    //   border-left-color: $bg-label-coming-soon;
    // }
  }

  &--content-manager {
    @include l-flag;
    background: $bg-label-content-manager;
    color: $text-green-color;
    // &::after{
    //   border-top-color: $bg-label-content-manager;
    //   border-bottom-color: $bg-label-content-manager; 
    //   border-left-color: $bg-label-content-manager;
    // }
  }

  &--tiger {
    @include l-flag;
    background: $bg-label-new;
    color: $text-link-color;
    // &::after{
    //   border-top-color: $bg-label-new;
    //   border-bottom-color: $bg-label-new; 
    //   border-left-color: $bg-label-new;
    // }
  }

  &--ready-to-use {
    @include l-flag;
    background: $bg-label-ready-to-use;
    // &::after{
    //   border-top-color: $bg-label-ready-to-use;
    //   border-bottom-color: $bg-label-ready-to-use; 
    //   border-left-color: $bg-label-ready-to-use;
    // }
  }

  &--draft {
    @include l-flag;
    background: $bg-label-draft;
    color: $text-yellow-color;
    // &::after{
    //   border-top-color: $bg-label-draft;
    //   border-bottom-color: $bg-label-draft;
    //   border-left-color: $bg-label-draft;
    // }
  }

  &--active-campaign {
    @include l-flag;
    background: $bg-label-active-campaign;
    color: $text-green-color;
    // &::after{
    //   border-top-color: $bg-label-active-campaign;
    //   border-bottom-color: $bg-label-active-campaign;
    //   border-left-color: $bg-label-active-campaign;
    // }
  }

  &--in-review {
    @include l-flag;
    background: $bg-in-review;
    color: $text-inreview-color;
  }

  &--published {
    @include l-flag;
    background: $bg-published;
    color: $text-published-color;
  }

  &--unpublished {
    @include l-flag;
    background: $bg-unpublished;
    color: $text-unpublished-color;
  }
}


.l-label-plus-input {
  display: flex;
  align-items: center;

  &__label-90 {
    width: auto; //CCM-3555 90px
    color: $text-body-color;
    text-align: right;
    padding-right: 16px;

    @media only screen and (min-width: 768px) {
      padding-left: 16px;
    }
  }

  &__label-100per {
    width: 100%;
    color: $text-body-color;
    text-align: left;
  }

  &__label-110 {
    width: 135px;
    color: $text-body-color;
    text-align: right;
    padding-right: 16px;
    padding-left: 10px;
  }

  &__input-wrap280 {
    // flex:1;
    width: 280px;
    max-width: 280px;

    .l-label-box-display {
      display: inline-block;
    }
  }

  &__input-wrap350 {
    // flex:1;
    width: 100%;
    max-width: 480px;

    .l-label-box-display {
      display: inline-block;
    }
  }
}

.l-flex-row {
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  &__left-col {
    display: flex;
  }

  &__right-col {
    display: flex;
    margin-left: auto;
  }

  .l-flex-row__col {
    .l-form-col {
      margin-bottom: 0;
    }

    &--w230 {
      width: 230px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .l-label-plus-input {
    float: right;
  }

  .l-flex-row {
    flex-direction: column;
    margin-bottom: 28px;

    &__left-col {
      width: 100%;
    }

    &__right-col {
      order: -1;
      flex: 1 0 100%;

      .l-flex-row__col {
        margin-bottom: 25px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .l-flex-row {
    &__left-col {
      width: 100%;
    }

    &__right-col {
      width: 100%;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 575px) {
  .l-label-plus-input {
    flex-direction: column !important;
    width: 100% !important;

    &__label-90 {
      width: 100%;
      text-align: left;
      margin-top: 0;
    }

    &__label-110 {
      width: 100%;
      margin-top: 0;
      text-align: left;
    }

    &__input-wrap {
      width: 100%;
      max-width: 100%;

      .l-label-box-display {
        padding-right: 40px;
        height: auto !important;
      }
    }

    &__input-wrap350 {
      width: 100%;
      max-width: 100%;

      .l-label-box-display {
        padding-right: 40px;
        height: auto !important;
      }
    }

    &__input-wrap280 {
      width: 100%;
      max-width: 100%;
    }
  }

  .l-flex-row {
    &__right-col {
      .l-flex-row__col {
        .l-btn {
          margin-left: auto;
        }
      }
    }
  }

  .l-110-width {
    width: 100%;
  }

  .l-110-margin-right {
    margin-right: 0;
    width: 100%;
    float: left;
  }

  .l-form-row.l-pad-lr24 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

//Password Tooltip Animation Start

.l-animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.l-slow {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


/**********
* Grow in *
***********/

@-webkit-keyframes growIn {
  0% {
    -webkit-transform: scale(0.2);
    opacity: 0;
  }

  50% {
    -webkit-transform: scale(1.2);

  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@keyframes growIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }

  50% {
    transform: scale(1.2);

  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.growIn {

  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  opacity: 0;
}

.growIn.go {
  -webkit-animation-name: growIn;
  animation-name: growIn;
}

.l-input-tooltip-wrap+div.l-input-tooltip {
  display: none;
  right: 0;
  width: 260px;
  background-color: black;
  font-size: 0.8em;
  color: $text-white-color;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 4;
  bottom: 115%;
  left: 40%;
  cursor: default;
}

.l-input-tooltip-wrap+div.l-input-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.l-input-tooltip-wrap:focus+div.l-input-tooltip {
  display: inline-table;
  -webkit-animation-name: growIn;
  animation-name: growIn;
}

//Password Tooltip Animation End

//No-data component css need to add there but in library component not used
.l-message-no-data-found {
  background: $bg-message-color;
  @include l-body1-semibold-txt;
  color: $text-body-color;
  padding: 24px 0;
  text-align: center;
  float: left;
  width: 100%;

  &__inner-wrap {
    width: 70%;
    // padding-right: 100px;
    margin: auto;
    position: relative;

    .material-icons-outlined {
      // position: absolute;
      // right: 0;
      // top:0;
      vertical-align: middle;
      margin-right: 10px;
    }

    p {
      margin-bottom: 0;
      display: inline-block;
    }
  }
}

//No-data End

.l-overlay {
  display: flex;
  flex-direction: column;
  position: relative;
  clear: both;
  transition: padding 0.6s ease-in-out;
  // z-index: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 5;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
  }

  &--show {
    min-height: 500px;

    &::before {
      opacity: 1;
      visibility: visible;
    }
  }
}

//Zoom Circle Effect Start
.l-circle-hov-effect {
  &:before {
    position: relative;
    z-index: 2;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    right: auto;
    top: 4px;
    margin: auto;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    -webkit-transform-origin: center;
    transform-origin: center;
    visibility: hidden;
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
  }

  &:hover:after {
    background: $bg-grey-color;
    visibility: visible;
    transform: scale(3);
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -ms-transform: scale(3);
    -o-transition: scale(3);
  }
}

//Zoom Circle Effect End

//Lable END

// @media only screen and (min-width: 1600px) {
//  html{
//    font-size: 20px;
//  }
// }

@media only screen and (max-width: 991px) {
  .l-container-top-pad {
    padding-top: 166px;
  }

  .l-page-top-pad {
    padding-top: 30px;
  }

  // .l-page-back{
  //   top: -60px;
  // } 
  .l-page-back {
    &--fix {
      position: relative;
      left: auto;
      margin-bottom: 30px;
    }
  }

  .l-page-title {
    .l-flag {
      top: auto;
      bottom: 12px;
      right: 50%;
      margin-right: -73px;
    }
  }

  #wrapper {
    padding-bottom: 132px;
  }
}

@media only screen and (max-width: 767px) {
  .l-container-top-pad {
    padding-top: 140px !important;
  }

  #wrapper {
    padding-bottom: 0;
  }

  /* fix footer hided so padding bottom removed*/

  .l-grey-bg-section {
    .l-page-title {
      margin-bottom: 30px;
    }
  }

  .l-form-row {
    .l-form-col {
      .col-sm-12 {
        margin-bottom: 16px;
      }
    }
  }

  .container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
}

@media only screen and (max-width: 480px) {
  .l-container-top-pad {
    padding-top: 139px !important;

    &--partner {
      padding-top: 176px !important;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


//custom combined material icon
.custom-combined {
  position: relative;
  display: inline-block;

  .material-icons-outlined.l-edit {
    position: absolute;
    left: 15px;
    top: 12px;
    font-size: 14px;
    background-color: $bg-white-color;
    padding: 0;
    margin: 0;
  }
}

//RTL Mode
[dir="rtl"] {
//CCM-3712 for dropdown RTL mode
  .l-form-col__icon__wrap {
    right: auto;
    left: 0;
  }

  .l-form-col__icon .l-text-box {
    padding-right: 1rem;
    padding-left: 35px;
  }

}